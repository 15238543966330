<template>
  <div>
    <b-table :mobile-cards="false" :data="data" bordered>
      <template #empty>
        <p class="has-text-centered">{{ $t('no') }} Data</p>
      </template>

      <b-table-column field="category" :label="$t('category')" v-slot="props">
        {{ props.row.category_transaction_name }}
      </b-table-column>
      <b-table-column field="action" label="" v-if="canEdit" v-slot="props">
        <div class="is-flex">
          <b-button class="mr-2" type="is-secondary is-light" icon-left="pencil-outline"
                    @click="editCategory(props.row)"></b-button>
          <b-button type="is-danger is-light" icon-left="trash-can-outline"
                    @click="deletePopup(props.row.category_transaction_id)"></b-button>
        </div>
      </b-table-column>
    </b-table>
    <b-modal
        v-model="modal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <finance-category-form @close="props.close" type="in" @submit="$emit('submit')"/>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import FinanceCategoryForm from "@/components/Finance/FinanceCategoryForm";
export default {
  name: "FinanceCategoryTable",
  components: {FinanceCategoryForm},
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    canEdit: Boolean
  },
  data: () => {
    return {
      modal: false,
    }
  },
  methods: {
    ...mapMutations('finance', [
      'UPDATE_SELECTED_CATEGORY'
    ]),

    ...mapActions('finance', [
      'deleteCustomTransactionCategory'
    ]),

    editCategory(value) {
      this.UPDATE_SELECTED_CATEGORY(value)
      this.modal = true
    },

    deletePopup(id) {
      this.$delete(() => {
        this.$loading()
        this.deleteCustomTransactionCategory(id).then(() => {
          this.$emit('submit')
        }).finally(() => {
          this.$loading(false)
        })
      })
    },
  }
}
</script>

<style scoped>

</style>