<template>
  <div class="card">
    <div class="card-content">
      <ValidationObserver ref="form">
        <form @submit.prevent="handleSubmit">
          <header class="mb-2">
            <div class="is-flex is-justify-content-space-between is-align-items-center">
              <h2 class="is-size-6 has-text-weight-bold mr-3 has-text-primary">Custom {{ $t('category') }} - {{ type === 'in' ? 'Pemasukan' : 'Pengeluaran' }}</h2>
              <div @click="$emit('close')">
                <b-icon class="cursor-pointer" icon="close-thick"/>
              </div>
            </div>
            <div>
              <p class="has-text-secondary">{{ selectedFarm.name }}</p>
            </div>
          </header>
          <section class="my-2">
            <ValidationProvider :name="$t('category')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('category')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input type="text" id="category_transaction_name" v-model="form.category_transaction_name"
                         :placeholder="`Input ${$t('category')}`"></b-input>
              </b-field>
            </ValidationProvider>
          </section>

          <footer class="has-text-right">
            <b-button type="is-primary is-gradient" native-type="submit">
              {{ $t('save') }}
            </b-button>
          </footer>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: "FinanceCategoryForm",
  props: {
    type: String,
  },
  data: () => {
    return {
      form: {},
    }
  },
  computed: {
    ...mapState('farm', [
      'selectedFarm'
    ]),

    ...mapState('finance', [
      'selectedCategory'
    ]),
  },
  created() {
    if (this.selectedCategory.category_transaction_id) this.form = {
      ...this.selectedCategory
    }
  },
  methods: {

    ...mapActions('finance', [
      'addCustomTransactionCategory',
      'updateCustomTransactionCategory',
    ]),

    handleSubmit() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.$loading()
          if (this.form.category_transaction_id) {
            this.updateCustomTransactionCategory(this.form).then(() => {
              this.$emit('submit')
              this.$emit('close')
            }).finally(() => {
              this.$loading(false)
            })
          } else {
            this.addCustomTransactionCategory({
              type: this.type,
              category_transaction_name: this.form.category_transaction_name
            }).then(() => {
              this.$emit('submit')
              this.$emit('close')
            }).finally(() => {
              this.$loading(false)
            })
          }
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 600px !important;
  max-width: 100%;
}
</style>
